<script setup lang="ts">

import { Image } from '@/Pages/the-country/interfaces/City'
import '@/../sass/hidden-gemz.scss'
import { User } from '@/types'
import { vOnClickOutside } from '@vueuse/components'
import OneFieldSignUpForm from '../the-signup/OneFieldSignUpForm.vue'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ListingTabs } from '@/Pages/the-listing/enums/listingTabs'

type Props = {
    user: User | null
    isUserLogged: boolean
    title: string
    slug: string
    image: Image
    preview?: string
    destinationName: string
    enteredEmail: string
    agreeWithPolicyAndConditions: boolean
    isSubmitted: boolean
    emailAuthorizationError: string
    isDisabled?: boolean
    emailExist: boolean,
    tooSoonSinceLastEmail: boolean,
    isSingleListing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    preview: '',
    isSingleListing: false
})

const emit = defineEmits([
    'closeModal',
    'submitModalSignUpForm',
    'updateEnteredEmail',
    'updateAgreeWithPolicyAndConditions',
    'closeModalAndScrollToTab'
])

const logicAfterAuthorization = ():void => {
    if(props.isSingleListing) {
        emit('closeModalAndScrollToTab', ListingTabs.ComparePrices)
    } else {
        emit('closeModal')
    }
}
</script>

<template>
    <div v-on-click-outside="() => emit('closeModal')" class="hidden-gemz-modal-content  rounded-lg  d-flex flex-wrap">
        <div class="modal-img col-12 col-md-5 px-3 p-md-0 img-cover-wrap mt-5 mt-md-0 hwm-image">
            <img class="img-fluid" src="/images/hiddenGemz/hidden-gemz-modal.webp" alt="hidden-gemz">
            <!-- <picture>
                <source :srcset="slide.pic.src.thumb_webp" type="image/webp">
                <source :srcset="slide.pic.src.thumb_def" type="image/jpg">

                <img
                    :alt="slide.name"
                    :title="slide.name"
                    loading="lazy"
                >
            </picture> -->
        </div>
        <div class="col-12 col-md-7 p-3 p-md-5">
            <template v-if="isUserLogged">
                <div v-if="user?.name" class="hw_form my-3">
                    <p class="title">
                        Hey {{ user.name }},
                    </p>

                    <div v-if="preview">
                        {{ preview }}
                    </div>
                    <p v-else>
                        You want to look behind the obvious, touristy stuff?
                    </p>
                </div>
                <div v-if="!emailExist" class="hw_steps mt-5">
                    <div class="hw_step-item pb-5">
                        <span class="hw_step-counter">1</span>
                        <p class="mb-0 lh-sm">
                            Use our links for your Hostel reservation
                        </p>
                        <p class="small text-gray-600 mb-0">
                            on Hostelworld and Booking.com
                        </p>
                    </div>
                    <div class="hw_step-item">
                        <span class="hw_step-counter">2</span>
                        <p class="mb-0 lh-sm pt-2 pt-md-0 pt-lg-2">
                            Get the Hidden Gemz
                        </p>
                    </div>
                </div>

                <div v-if="emailExist" class="hw_steps mt-5">
                    <div class="hw_step-item pb-5">
                        <span class="hw_step-counter">1</span>
                        <p class="mb-0 lh-sm">
                            Create Account
                        </p>
                        <p class="small text-gray-600 mb-0">
                            Create your Free Account
                        </p>
                    </div>
                    <div class="hw_step-item pb-5">
                        <span class="hw_step-counter">2</span>
                        <p class="mb-0 lh-sm">
                            Use our links for your Hostel reservation
                        </p>
                        <p class="small text-gray-600 mb-0">
                            on Hostelworld and Booking.com
                        </p>
                    </div>
                    <div class="hw_step-item">
                        <span class="hw_step-counter">3</span>
                        <p class="mb-0 lh-sm pt-2 pt-md-0 pt-lg-2">
                            Get the Hidden Gemz in {{ destinationName }}
                        </p>
                    </div>
                </div>

                <div v-if="emailExist" class="well mt-4">
                    The email address "{{ enteredEmail }}" is already registered as a Hostelz.com user. <a href="/login" class="cl-link">Login</a> here.
                </div>

                <div v-if="emailExist">
                    <hr class="my-4">
                    <p class="text-center">
                        <small>Do you already have an account? <a href="/login">Login</a></small>
                    </p>
                </div>
                
                <div v-if="!emailExist" class="hw_form my-3 d-flex justify-content-center">
                    <button
                        type="button"
                        class="btn hg-btn bg-primary text-white mt-5 text-capitalize"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="logicAfterAuthorization"
                    >
                        {{ isSingleListing ? 'Finalize your Reservation!' : `Pick your Hostel in ${ destinationName }` }} 
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="hw_steps mt-5">
                    <div class="hw_step-item pb-5">
                        <span class="hw_step-counter">1</span>
                        <p class="mb-0 lh-sm">
                            Create Account
                        </p>
                        <p class="small text-gray-600 mb-0">
                            Create your Free Account
                        </p>
                    </div>
                    <div class="hw_step-item pb-5">
                        <span class="hw_step-counter">2</span>
                        <p class="mb-0 lh-sm">
                            Use our links for your Hostel reservation
                        </p>
                        <p class="small text-gray-600 mb-0">
                            on Hostelworld and Booking.com
                        </p>
                    </div>
                    <div class="hw_step-item">
                        <span class="hw_step-counter">3</span>
                        <p class="mb-0 lh-sm pt-2 pt-md-0 pt-lg-2">
                            Get the Hidden Gemz in {{ destinationName }}
                        </p>
                    </div>

                    <div v-if="emailExist" class="well mt-4">
                        The email address "{{ enteredEmail }}" is already registered as a Hostelz.com user. <a href="/login" class="cl-link">Login</a> here.
                    </div>

                    <div v-if="emailExist">
                        <hr class="my-4">
                        <p class="text-center">
                            <small>Do you already have an account? <a href="/login">Login</a></small>
                        </p>
                    </div>
                </div>
                <div
                    v-if="tooSoonSinceLastEmail"
                    class="alert alert-warning w-100 text-center mb-0 hostels-list-item-partial-availability-btn mt-4"
                >
                    <FontAwesomeIcon :icon="faExclamationCircle" class="fa-fw mr-1" />

                    We've sent a confirmation email to this address. Please verify your account to continue.
                </div>
                <div v-if="!emailExist" class="hw_form mt-4 mb-3">
                    <OneFieldSignUpForm
                        :entered-email="enteredEmail"
                        :agree-with-policy-and-conditions="agreeWithPolicyAndConditions"
                        :is-submitted="isSubmitted"
                        :email-authorization-error="emailAuthorizationError"
                        :is-disabled="isDisabled"
                        @update-entered-email="emit('updateEnteredEmail', $event)" 
                        @update-agree-with-policy-and-conditions="emit('updateAgreeWithPolicyAndConditions', $event)"
                        @submit-modal-sign-up-form="emit('submitModalSignUpForm', $event)"
                    />

                    <div v-if="!isSubmitted">
                        <hr class="my-4">
                        <p class="text-center">
                            <small>Enter your email, and we'll send you an activation link.</small>
                        </p>
                    </div>
                   
                    <div>
                        <hr class="my-4">
                        <p class="text-center">
                            <small>Do you already have an account? <a href="/login">Login</a></small>
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
.hidden-gemz-modal-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: white;
    overflow: hidden;
}

:deep(.one-field-sign-up-form-tooltip) {
    display: none;
}
</style>