<script setup lang="ts">

import { onUnmounted, ref, watch } from 'vue'
import { Image } from '@/Pages/the-country/interfaces/City'
import TheImageWebpThumb from '@/Components/the-image/TheImageWebpThumb.vue'
import 'styles/hidden-gemz.scss'
import { User } from '@/types'
import {
    signUpEndpoint,
    isLoadingRequestAuthorization,
    setAuthorizationStatus,
    clearAuthorizationFormData,
    emailAuthorizationError,
    emailExist,
    isSubmitted,
    tooSoonSinceLastEmail,
    isUserLogged
} from '@/Composables/authorization'
import AppModal from '@/Components/the-modal/AppModal.vue'
import HiddenGemzModalContent from '@/Components/hidden-gemz/HiddenGemzModalContent.vue'

defineProps<{
    user: User | null
    title: string
    slug: string
    image: Image
    preview?: string
    destinationName: string
    url?: string
    isSingleListing?: boolean
}>()

const emit = defineEmits(['tabsSwitchAndScroll'])

const showSignUpModal = ref(false)

const openShowSignUpModal = (): void => {
    showSignUpModal.value = true
}

const closeShowSignUpModal = (): void => {
    showSignUpModal.value = false
}

const enteredEmail = ref('')

const updateEnteredEmail = (event: Event): void => {
    const target = event.target as HTMLInputElement

    enteredEmail.value = target.value
}

const agreeWithPolicyAndConditions = ref(false)

const updateAgreeWithPolicyAndConditions = (event: Event): void => {
    const target = event.target as HTMLInputElement

    agreeWithPolicyAndConditions.value = target.checked
}

const submitModalSignUpForm = (): void => {

    agreeWithPolicyAndConditions.value = false

    const request = {
        url: '/signup',
        email: enteredEmail.value
    }

    signUpEndpoint(request, (data) => setAuthorizationStatus(data))
}

const closeModalAndScrollToTab = (targetTab: string): void => {
    closeShowSignUpModal()

    emit('tabsSwitchAndScroll', targetTab)
}

onUnmounted(() => {
    clearAuthorizationFormData()
})

watch(() => showSignUpModal.value, () => {
    if (showSignUpModal.value === false) {
        enteredEmail.value = ''

        agreeWithPolicyAndConditions.value = false

        clearAuthorizationFormData()
    }
})

</script>

<template>
    <div class="hidden-gemz rounded-lg">
        <div class="hg-image dark-overlay hidden-gemz-wrap-img">
            <TheImageWebpThumb :image="image" class="card-img-top" />
        </div>
        <div class="hg-content">
            <h3 class="text-white">
                {{ title }}
            </h3>
            <button
                type="button"
                class="btn hg-btn cl-second bg-white rounded-md font-weight-600"
                @click="openShowSignUpModal"
            >
                How it works
            </button>
        </div>

        <AppModal
            :show="showSignUpModal"
            class="hiddenGemzModal"
            @close="closeShowSignUpModal"
        >
            <HiddenGemzModalContent
                :user="user"
                :is-user-logged="isUserLogged"
                :title="title"
                :slug="slug"
                :image="image"
                :preview="preview"
                :destination-name="destinationName"
                :entered-email="enteredEmail"
                :agree-with-policy-and-conditions="agreeWithPolicyAndConditions"
                :is-submitted="isSubmitted"
                :email-authorization-error="emailAuthorizationError"
                :is-disabled="isLoadingRequestAuthorization"
                :email-exist="emailExist"
                :too-soon-since-last-email="tooSoonSinceLastEmail"
                :is-single-listing="isSingleListing"
                @close-modal="closeShowSignUpModal"
                @submit-modal-sign-up-form="submitModalSignUpForm"
                @update-entered-email="updateEnteredEmail"
                @update-agree-with-policy-and-conditions="updateAgreeWithPolicyAndConditions"
                @close-modal-and-scroll-to-tab="closeModalAndScrollToTab"
            />

            <template #close-icon>
                <span class="close-modal-btn">&times;</span>
            </template>
        </AppModal>
    </div>
</template>

<style scoped lang="scss">
.hidden-gemz {
    &-wrap {
        &-img {
            &:deep(.image-webp-thumb) {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .hg-content h3 {
        margin-bottom: 24px;
    }
}

.close-modal-btn {
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 #fff;
    display: flex;

    transition: color 0.3s, opacity 0.3s;
}
</style>

<style lang="scss">
.hiddenGemzModal {
    .app-modal {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 1601;
    }

    .app-modal-close-btn {

        &:hover {
            .close-modal-btn {
                opacity: 1;
            }
        }
    }

    .app-modal-dialog {
        margin: 16px 8px;
        background-color: transparent;
        box-shadow: none;
        min-height: calc(100% - 1rem);
        display: flex;

        align-items: center;


        @media (min-width: 576px) {
            margin: 1.75rem auto;
            min-height: calc(100% - 3.5rem);
            max-width: 500px;
        }

        @media (min-width: 992px) {
            max-width: 800px;
        }
    }

    .app-modal-close-btn {
        top: 8px;
        right: 8px;

        @media (min-width: 768px) {
            top: 30px;
            right: 26px;
        }
    }
}
</style>